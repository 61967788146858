import * as React from 'react';

import type { History } from 'history';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import type { ReactAppOrParcel } from 'single-spa-react';
import { SegmentPlugin } from '@edapp/analytics-tracking';

import { itly } from '@edapp/analytics-tracking';
import { ErrorLogger, SentryAdapter } from '@edapp/monitoring';
import type { ThemeSetting } from '@edapp/themes';
import { initMFELocalization } from '@edapp/translations';
import { HostedWebviewUtils } from '@edapp/uxp-webview';

import { Error } from './components/Error';

type ExtraProps = {
  scHistory: History;
  lang: string;
  theme: ThemeSetting;
  basename?: string;
};

const lifecycles = singleSpaReact<ExtraProps>({
  React,
  ReactDOM,
  loadRootComponent: () =>
    import(/* webpackChunkName: "mfe-manage-app" */ './app').then(({ App }) => props => {
      const { scHistory, theme, basename } = props as ExtraProps;
      return <App scHistory={scHistory} theme={theme} basename={basename} />;
    }),
  errorBoundary: err => {
    ErrorLogger.captureEvent('ErrorBoundary', 'error', { err });
    return <Error />;
  }
});

const bootstrap: ReactAppOrParcel<ExtraProps>['bootstrap'] = async ({ lang, ...args }) => {
  ErrorLogger.init(() => new SentryAdapter({ app: 'training' }));

  try {
    await initMFELocalization(lang, [
      'translation',
      'ed-components',
      'formio',
      'leaderboard',
      'lesson-navigation',
      'course-translation',
      'sc-web-ui',
      'analytics',
      'certificates',
      'tables',
      'slides'
    ]);
  } catch (e) {
    HostedWebviewUtils.triggerBootstrapError('load_i18n_failed');
    throw e;
  }

  try {
    itly.load({
      validation: {
        disabled: false,
        trackInvalid: true,
        errorOnInvalid: true
      } as any,
      // Disable default destinations,
      // ensure we can use the custom segment plugin
      // which inherits the Segment instance from the shell application
      destinations: {
        all: { disabled: true }
      },
      plugins: [new SegmentPlugin()]
    });
  } catch (e) {
    HostedWebviewUtils.triggerBootstrapError('load_itly_failed');
    throw e;
  }

  await lifecycles.bootstrap({ lang, ...args });
};

const mount: ReactAppOrParcel<ExtraProps>['mount'] = async (...args) => {
  await lifecycles.mount(...args);
};

const unmount: ReactAppOrParcel<ExtraProps>['unmount'] = async (...args) => {
  await lifecycles.unmount(...args);
};

export { bootstrap, mount, unmount };
